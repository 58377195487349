<h4>Navigate to...</h4>
<div class="list-group">
    {{#each t}}
    <a href="/{{URL}}" class="list-group-item track-mru" onclick="Beacon.Home.trackMRU('{{@root.ID}}',this); return true;">
        <span class="glyphicon glyphicon-chevron-right pull-right right-chev"></span>
        <h3 class="list-group-item-heading">{{Description}}</h3>
        <p class="list-group-item-text">{{Text}}</p>
    </a>
    {{/each}}
</div>
<div>
    {{#if isFavorite}}
    <a href="#" class="btn btn-default btn-xs" onclick="Beacon.Home.removeFavorite('{{ID}}'); return false;">
        <span class="glyphicon glyphicon-star active"></span>
        Remove favorite
    </a>
    {{else}}
    <a href="#" class="btn btn-default btn-xs" onclick="Beacon.Home.saveFavorite('{{ID}}'); return false;">
        <span class="glyphicon glyphicon-star inactive"></span>
        Save as favorite
    </a>
    {{/if}}

    {{#if isDefault}}
    <a href="#" class="btn btn-default btn-xs pull-right" onclick="Beacon.Home.clearDefaultApp(); return false;">
        <span class="glyphicon glyphicon-star active"></span>
        Clear default
    </a>
    {{else}}
    <a href="#" class="btn btn-default btn-xs pull-right" onclick="Beacon.Home.saveDefaultApp('{{ID}}'); return false;">
        <span class="glyphicon glyphicon-star inactive"></span>
        Save as default
    </a>
    {{/if}}
</div>
