<h2>
    Announcements
    {{#if Name}}
    <small>for {{Name}}</small>
    {{/if}}
</h2>
<ul>
    {{#each Announcements}}
    <li>
        <span class="glyphicon glyphicon glyphicon-calendar"></span>
        {{{Text}}}
    </li>
    {{/each}}
</ul>
