<div id="clientMenuBlank" class="dropdown-option all-option" role="option">&nbsp;</div>
{{#if Favorites}}
<div class="state-group" role="group" aria-labelledby="favorites-group">
    <div id="favorites-group" class="state-group-label faves">Favorites</div>
    {{#each Favorites}}
    <div id="faves{{ID}}{{sanitizedName}}" class="dropdown-option faves" data-appid="{{ID}}" role="option">{{DisplayName}}</div>
    {{/each}}
</div>
{{/if}}
{{#if MRU}}
<div class="state-group" role="group" aria-labelledby="mru-group">
    <div id="mru-group" class="state-group-label faves">Recently used</div>
    {{#each MRU}}
    <div id="mru{{ID}}{{sanitizedName}}" class="dropdown-option faves" data-appid="{{ID}}" role="option">{{DisplayName}}</div>
    {{/each}}
</div>
{{/if}}
{{#each States}}
<div class="state-group" data-state="{{Name}}" role="group" aria-labelledby="{{id}}-label">
<div id="{{id}}-label" class="state-group-label">{{Name}}</div>
{{#each Apps}}
<div id="client{{ID}}{{sanitizedName}}" class="dropdown-option" data-appid="{{ID}}" role="option">{{DisplayName}}</div>
{{/each}}
</div>
{{/each}}